import { Nav } from 'react-bootstrap';

function NavBar() {
    return(
        <>
            {/* <Nav className="justify-content-center" activeKey="/home">
                <Nav.Item>
                <Nav.Link href="/home">Active</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                <Nav.Link eventKey="link-1">Link</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                <Nav.Link eventKey="link-2">Link</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                <Nav.Link eventKey="disabled" disabled>
                    Disabled
                </Nav.Link>
                </Nav.Item>
            </Nav> */}
            <Nav className="justify-content-end" activeKey="/">
                <Nav.Item>
                    <Nav.Link href="/about">
                        About
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                <Nav.Link href="/project">Project</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                <Nav.Link href="/resume">Resume</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                {/* <Nav.Link eventKey="disabled" disabled>
                    Disabled
                </Nav.Link> */}
                </Nav.Item>
            </Nav>
            
            <p className="text-center mt-4 mb-4">Kevin Kim</p>
            
        </>
    )
}

export default NavBar;