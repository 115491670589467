import { Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import AboutLink from './About.jsx'

function LeftSection() 
{
    let link = window.location.pathname;

    return (
        <Container>
            <Row>
                {/* Left Section */}
                <Col sm={4}>
                    <Row>
                        Picture
                    </Row>
                    <Row>
                        Kevin Kim
                    </Row>
                    <Row>
                        www.linkedin.com/in/kyung-kim-k55
                    </Row>
                    <Row>
                        https://github.com/kevin1105
                    </Row>
                </Col>

                {/* Right Section */}
                <Col lg={true}>
                    {/* Details for About, Project, Resume based on link /n */}
                    {
                        link === '/about' ? (
                            AboutLink()
                        ) 
                        : 
                        link === '/project' ? (
                            'Project'
                        )
                        :
                        link === '/resume' ? (
                            'Resume'
                        )
                        :
                        (
                            'This Page Does Not Exist'
                        )
                    }

                </Col>
            </Row>
        </Container>
    )
}

export default LeftSection;