
function About() 
{

    return(
        <div>
            <div>
                <p>
                        WORK HISTORY
                </p>
                <p>
                        ORANGE COUNTY COMMUNITY FOUNDATION
                </p>
                <p>
                        SOFTWARE SUPPORT ENGINEER JUNE 2021 – CURRENT
                </p>
                <p>
                        CHEMCO PRODUCTS COMPANY
                </p>
                <p>
                        SOFTWARE SUPPORT ENGINEER NOVEMBER 2019 – MAY 2021
                </p>
            </div>
            <div>
                <p>
                        EDUCATION
                </p>
                <p>
                        B.S. COMPUTER SCIENCE
                </p>
                <p>
                        UNIVERSITY OF SAN FRANCISCO
                </p>
                <p>
                        GRADUATED: MAY 2019
                </p>
            </div>
        </div>
    )
}

export default About;